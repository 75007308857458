import * as fcl from "@onflow/fcl"

const USE_LOCAL = true

var baseUrl = ""
if (typeof window !== "undefined") {
  // Client-side-only code
  var getUrl = window.location
  baseUrl = getUrl.protocol + "//" + getUrl.host
}
// prettier-ignore
fcl.config()
  .put("app.detail.title", "Test Harness")
  .put("app.detail.icon", "https://placekitten.com/g/200/200")
  .put("service.OpenID.scopes", "email")
  .put("fcl.appDomainTag", "harness-app")

// prettier-ignore
fcl.config()
    .put("flow.network", "local")
    .put("env", "local")
    .put("accessNode.api", "http://localhost:8888")
    .put("discovery.wallet", baseUrl + "/fcl/authn")
